import React, {Component} from 'react';
import styles from './PostView.module.css';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import LikeIcon from '../svgs/LikeIcon';
import ChevronBackIcon from '../svgs/ChevronBackIcon';
import ReplyIcon from '../svgs/ReplyIcon';
import {matchPath, withRouter} from 'react-router-dom';
import {rwbApi} from '../../../../shared/apis/api';
import {
  cleanLinkID,
  filterPostMedia,
  getHyperlinks,
  howLongAgo,
} from '../../../../shared/utils/Helpers';
import PhotoView from './PhotoView';
import FormattedPostText from './FormattedPostText';
import Loading from '../Loading';
import {
  EXECUTION_STATUS,
  extractFeedOriginInfo,
  logAccessPostPhoto,
  logLikePost,
  logViewLikeList,
  webSectionName,
} from '../../../../shared/models/Analytics';
import ReportAndDeleteOverlay from '../ReportAndDeleteOverlay';
import {userProfile} from '../../../../shared/models/UserProfile';
import CommentView from './CommentView';
import CreateCommentBar from './CreateCommentBar';
import {ClipLoader} from 'react-spinners';
import ShareChallengeBox from './ShareChallengeBox';
import {
  POST_BLOCK_WARNING,
  POST_DELETE_WARNING,
} from '../../../../shared/constants/OtherMessages';
import {
  POST_BLOCK_ERROR,
  POST_DELETE_ERROR,
} from '../../../../shared/constants/ErrorMessages';
import {deletePost} from '../../../../shared/utils/StreamHelpers';
import PinnedPostIcon from '../svgs/PinnedPostIcon';
import SitePreviewCard from '../cards/SitePreviewCard';
import Carousel from './Carousel';

function Reply() {
  return (
    <div className={styles.replyContainer}>
      <div className={styles.userReplyContainer}>
        <ReplyIcon className={styles.replyIcon} />
        <p>
          <span className="namesAndObjects">User&nbsp;</span>• Time
        </p>
      </div>
      <p className={`bodyCopy ${styles.postText}`}>Text</p>
      <div className={styles.reactionContainer}>
        <div className={styles.likeContainer}>
          <LikeIcon className={styles.likeIcon} />
          <p>#</p>
        </div>
      </div>
    </div>
  );
}

function Comment() {
  return (
    <div className={styles.commentContainer}>
      <div className={styles.userCommentContainer}>
        <div className={styles.userImageContainer}>
          <img
            className={styles.profileImage}
            src={DefaultProfileImg}
            alt="User Profile Image"
          />
        </div>
        <p>
          <span className="namesAndObjects">User&nbsp;</span>• Time
        </p>
      </div>
      <p className={`bodyCopy ${styles.postText}`}>Text</p>
      <div className={styles.reactionContainer}>
        <div className={styles.likeContainer}>
          <LikeIcon className={styles.likeIcon} />
          <p>#</p>
        </div>
        <p># Reply</p>
      </div>
      <Reply />
    </div>
  );
}

const SeePreviousComments = ({refreshing, onClick, allCommentsLoaded}) => (
  <div
    className={[
      styles.previousCommentsContainer,
      allCommentsLoaded && styles.displayNone,
    ].join(' ')}>
    {refreshing ? (
      <ClipLoader size={30} color={'var(--grey80)'} loading={true} />
    ) : (
      <h3 className={styles.previousCommentsText} onClick={onClick}>
        See Previous Comments
      </h3>
    )}
  </div>
);

class PostView extends Component {
  constructor(props) {
    super(props);
    this.feedContainerRef = React.createRef();
    const {
      eventID = null,
      eventName = null,
      groupName = null,
      groupID = null,
      challengeName = null,
      challengeID = null,
      likeAmount = null,
      liked = null,
      media_url = null,
      post_images = [],
      poster = null,
      streamID = null,
      tagged = null,
      text = null,
      time = null,
      title = null,
      postID = null,
      previousScreen = null,
      actorID = null,
      activityID = null,
      commentAmount = null,
      commentID = null,
      groupRecordType = null,
      eventRecordType = null,
      activitySubType = null,
      isPinned = null,
      isAdmin = null,
    } = this.props.location.state ?? {};

    this.state = {
      eventID,
      eventName: eventName || 'posted',
      groupName: groupName || 'posted',
      groupID: groupID || null,
      challengeName: challengeName || 'posted',
      challengeID: challengeID || null,
      likeAmount: likeAmount || 0,
      liked: liked || false,
      media_url,
      post_images,
      poster,
      streamID,
      tagged,
      text,
      time,
      title,
      postID,
      previousScreen,
      loading: true,
      imageFocused: false,
      refreshing: false,
      isModalOpen: false,
      actorID: actorID || null,
      activityID: activityID || null,
      comments: [],
      commentAmount: commentAmount || 0,
      page: 1,
      allCommentsLoaded: commentAmount === 0 ? true : false,
      loadingPoster: commentID ? true : false, // requires fetching poster data
      edited: false,
      workoutEventName: '',
      chapterName: '',
      eventStartTime: '',
      miles: 0,
      steps: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      groupRecordType: groupRecordType || null,
      eventRecordType,
      activitySubType,
      isAdmin: isAdmin || false,
      isPinned: isPinned || false,
      graphData: null,
      currentImageIndex: 0,
      selectedImage: 0,
      feedWidth: 0,
    };
  }

  componentDidMount() {
    this.initializeScreen();
    this.feedContainerIsValid();
    window.addEventListener('resize', this.updateFeedWidth);
  }

  feedContainerIsValid = () => {
    if (this.feedContainerRef.current) {
      this.updateFeedWidth();
    } else {
      setTimeout(this.feedContainerIsValid, 100);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateFeedWidth);
  }

  updateFeedWidth = () => {
    if (this.feedContainerRef.current) {
      this.setState({
        feedWidth: this.feedContainerRef.current.offsetWidth
      });
    }
  };

  initializeScreen = () => {
    let {poster, streamID} = this.state;
    if (!streamID && webSectionName() !== 'Feed') {
      // assume this is a link and we are lacking the needed data
      cleanLinkID(window.location.href.split('feed/')[1]);
      let cleanedURL = cleanLinkID(window.location.href);
      const postAndUserId = cleanedURL.split('/feed/')[1];
      window.location.href = `${window.location.origin}/feed/${postAndUserId}`;
      // unable to load the post without a user id
    } else {
      if (!streamID || !poster?.id) {
        const postAndUserId = window.location.pathname
          .split('/feed/')[1]
          .split('/user/');
        if (!poster) poster = {};
        poster.id = postAndUserId[1];
        streamID = postAndUserId[0];
      }
      this.setState(
        {
          poster,
          streamID,
        },
        () => {
          rwbApi
            .getSpecificPost(poster?.id, streamID)
            .then((result) => {
              const data = result;
              // If loading the specific post has a name (meaning it was an event), display it. Otherwise it was a post
              let eventName,
                verb,
                eventID,
                groupName,
                groupID,
                challengeName,
                challengeID;
              if (data.name) eventName = data.name;
              else if (data.event && data.event.name) {
                eventName = data.event.name;
                verb = data.verb;
                eventID = data.event.id;
              } else if (data.group && data.group.name) {
                groupName = data.group.name;
                verb = data.verb;
                groupID = data.group.id;
              } else if (data.challenge) {
                challengeName = data.challenge.name;
                verb = data.verb;
                challengeID = data.challenge.id;
              } else {
                eventName = 'posted';
              }
              if (!groupID && this.state.groupID) groupID = this.state.groupID;
              const isGroupPostView = matchPath(window.location.pathname, {
                path: '/groups/:groupId/feed/:streamId',
                exact: true,
              });
              const feedOrigin = extractFeedOriginInfo(
                data.foreign_id,
                data.object,
              );
              this.setState({
                event: data.event,
                eventRecordType:
                  data?.event?.is_virtual != null
                    ? data?.event.is_virtual
                      ? 'virtual'
                      : 'event'
                    : null,
                group: data.group,
                groupRecordType: data?.group?.type,
                challenge: data.challenge,
                foreign_id: data.foreign_id,
                feedOrigin,
                eventName,
                groupName,
                challengeName,
                challengeID,
                verb,
                text: data.text,
                media_url: data.media_url,
                post_images: filterPostMedia(data?.media, 'image'),
                liked: data.own_reactions.like || false,
                likeAmount: data.reaction_counts?.like || 0,
                loading: isGroupPostView ? true : false,
                tagged: data.tagged,
                eventID: eventID || this.state.eventID,
                groupID: groupID || this.state.groupID,
                poster: data.user,
                time: data.time,
                loadingPoster: false,
                edited: data.edited,
                graphData: data.open_graph,
                commentAmount: data.reaction_counts?.comment || 0,
              });
              if (data.workout) {
                this.setState({
                  workoutEventName: data.workout.event_name || '',
                  chapterName: data.workout.chapter_name || '',
                  eventStartTime: data.workout.event_start_time || '',
                  miles: data.workout.miles || 0,
                  steps: data.workout.steps || 0,
                  hours: Math.floor(data.workout.minutes / 60) || 0,
                  minutes: Math.floor(data.workout.minutes % 60) || 0,
                  seconds: Math.round((data.workout.minutes % 1) * 60) || 0,
                });
              }
              if (isGroupPostView) this.checkGroupAdmin(groupID);
            })
            .catch((err) => {
              console.warn(err);
              this.setState({loading: false});
              this.props.history.goBack();
            });
          this.refreshReactions();
        },
      );
    }
  };

  checkGroupAdmin = (id) => {
    rwbApi
      .isGroupAdmin(id)
      .then((result) => {
        this.setState({
          isAdmin: result.is_admin,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
          loadingPoster: false,
        });
      });
  };

  // Refresh how many comments/likes on the post
  refreshReactions = (newComment = false) => {
    let {poster, streamID} = this.state;
    this.setState({refreshing: true});
    rwbApi
      .getReactions(poster?.id, streamID, null, 'comment')
      .then((result) => {
        const comments = [];
        result.data.reactions.forEach((item) =>
          comments.push({
            content: item.data.content,
            time: item.created_at,
            comment_id: item.id,
            user: item.user,
            tagged: item.data.tagged,
            edited: item.data.edited,
            own_reactions: item.own_like_id,
            reaction_counts: item.children_counts.like || 0,
            offset: null,
            open_graph: item.data?.open_graph,
          }),
        );
        this.setState(
          {
            comments,
            allCommentsLoaded:
              comments.length === 0 ||
              comments.length >= result.data.reaction_counts.comment
                ? true
                : false,
            refreshing: false,
          },
          () =>
            newComment &&
            this.commentsRef.scrollIntoView({
              block: 'end',
              behavior: 'smooth',
            }),
        );
      })
      .catch((err) => {
        console.warn(err);
        this.setState({refreshing: false});
        alert('Error refreshing post. Post not found. Please try again later');
        this.props.history.goBack();
      });
  };

  loadMoreReactions = () => {
    const {poster, streamID, page, comments} = this.state;
    this.setState({refreshing: true});
    const oldestCommentID = comments[0].comment_id;
    rwbApi
      .getReactions(poster?.id, streamID, oldestCommentID, 'comment')
      .then((result) => {
        const newComments = [];
        result.data.reactions.forEach((item) =>
          newComments.push({
            content: item.data.content,
            time: item.created_at,
            comment_id: item.id,
            user: item.user,
            tagged: item.data.tagged,
            edited: item.data.edited,
            own_reactions: item.own_like_id,
            reaction_counts: item.children_counts.like || 0,
            offset: oldestCommentID,
          }),
        );
        const mergedComments = [...newComments, ...comments];
        this.setState({
          comments: mergedComments,
          likeAmount: result.data.reaction_counts?.like || 0,
          commentAmount: result?.data?.reaction_counts?.comment || 0,
          page: page + 1,
          allCommentsLoaded:
            mergedComments.length >= result.data.reaction_counts.comment
              ? true
              : false,
          refreshing: false,
        });
      })
      .catch((err) => {
        console.warn(err);
        this.setState({refreshing: false});
        alert('Error loading more comments. Please try again later');
      });
  };

  updateLikeStatus = (action) => {
    if (action === 'like')
      this.setState({liked: true, likeAmount: this.state.likeAmount + 1});
    else if (action === 'unlike')
      this.setState({liked: false, likeAmount: this.state.likeAmount - 1});
  };

  handleLikeClicked = () => {
    const reactionKind = JSON.stringify({kind: 'like'});
    if (this.state.eventID) this.handleEventLike(reactionKind);
    else this.handleUserLike(reactionKind);
  };

  handleUserLike = (reactionKind) => {
    const {liked, poster, streamID, previousScreen} = this.state;
    let analyticsObj = this.baseAnalyticsObj();
    if (!liked) {
      this.updateLikeStatus('like');
      rwbApi
        .postReaction(poster?.id, streamID, reactionKind)
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
        })
        .catch((err) => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          alert('Error liking post. Please try again later.');
          this.updateLikeStatus('unlike');
        })
        .finally(() => {
          logLikePost(analyticsObj);
        });
    } else {
      this.updateLikeStatus('unlike');
      rwbApi
        .deleteReaction(poster?.id, streamID, reactionKind)
        .then(() => {})
        .catch((err) => {
          this.updateLikeStatus('like');
          alert('Error unliking post. Please try again later.');
        })
        .finally(() => {});
    }
  };

  handleEventLike = (reactionKind) => {
    const {liked, eventID, streamID, previousScreen} = this.state;
    let analyticsObj = this.baseAnalyticsObj();
    if (!liked) {
      this.updateLikeStatus('like');
      rwbApi
        .postEventReaction(eventID, streamID, reactionKind)
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
        })
        .catch((err) => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          console.warn(err);
          alert('Error liking post. Please try again later.');
          this.updateLikeStatus('unlike');
        })
        .finally(() => {
          logLikePost(analyticsObj);
        });
    } else {
      this.updateLikeStatus('unlike');
      rwbApi
        .deleteEventReaction(eventID, streamID, reactionKind)
        .then(() => {})
        .catch((err) => {
          this.updateLikeStatus('like');
          alert('Error unliking post. Please try again later.');
        });
    }
  };

  // only the creator of a post can delete
  // "event" types are statuses, and those cannot be deleted
  canDelete = () => {
    const postCreator = this.state.actorID; //ID of post creator
    const currentUserID = userProfile.getUserProfile().id.toString();
    return postCreator === currentUserID && this.props.type !== 'event';
  };

  baseAnalyticsObj = () => {
    const {
      eventID,
      activityID,
      event,
      challenge,
      group,
      streamID,
      groupRecordType,
      feedOrigin,
      activitySubType,
      eventRecordType,
    } = this.state;
    let analyticsObj = {
      object_post: streamID,
      feed_origin_name: feedOrigin.name,
      feed_origin_type: feedOrigin.type,
      section_name: webSectionName(),
    };
    if (event?.id) {
      analyticsObj.event_id = `${event.id}`;
      analyticsObj.activity_sub_type = event.category;
      analyticsObj.event_record_type = event.is_virtual ? 'virtual' : 'event';
    }
    if (group?.id) analyticsObj.group_id = `${group.id}`;
    if (challenge?.id) analyticsObj.challenge_id = `${challenge.id}`;
    if (groupRecordType) analyticsObj.group_record_type = groupRecordType;
    if (eventRecordType) analyticsObj.event_record_type = eventRecordType;
    if (activitySubType) analyticsObj.activity_sub_type = activitySubType;
    return analyticsObj;
  };

  deletePost = () => {
    if (window.confirm(`Delete Post: ${POST_DELETE_WARNING}`)) {
      const {eventID, activityID, groupID, challengeID} = this.state;
      let analyticsObj = this.baseAnalyticsObj();
      analyticsObj.click_text = 'delete post';
      this.setState({deleted: true});
      deletePost(activityID, eventID, challengeID, groupID, analyticsObj)
        .then(() => {
          this.props.history.goBack();
        })
        .catch(() => {
          this.setState({deleted: false});
        })
        .finally(() => {});
    }
  };

  blockPost = () => {
    if (window.confirm(`Block Post: ${POST_BLOCK_WARNING}`)) {
      const {eventID, activityID} = this.state;
      if (!eventID) {
        this.setState({deleted: true});
        rwbApi
          .blockPost(activityID)
          .then(() => {
            this.props.history.goBack();
          })
          .catch(() => {
            alert(POST_BLOCK_ERROR);
            this.setState({deleted: false});
          });
      }
    }
  };

  setImageIndex = (index) => {
    this.setState({ currentImageIndex: index });
  };

  setSelectedImage = (newIndex) => {
    this.setState({ selectedImage: newIndex });
  };

  multiPhotoClicked = (index) => {
    logAccessPostPhoto(this.baseAnalyticsObj());
    this.setState({ isModalOpen: true }); 
    this.setState({ selectedImage: index })
  }

  handleCommentDeletion = () =>
    this.setState({commentAmount: this.state.commentAmount - 1});

  render() {
    const {
      eventName,
      groupName,
      challengeName,
      likeAmount,
      liked,
      media_url,
      post_images,
      poster,
      streamID,
      tagged,
      text,
      time,
      title,
      isModalOpen,
      loading,
      verb,
      eventID,
      groupID,
      challengeID,
      actorID,
      comments,
      refreshing,
      commentAmount,
      allCommentsLoaded,
      loadingPoster,
      edited,
      workoutEventName,
      chapterName,
      eventStartTime,
      miles,
      steps,
      hours,
      minutes,
      seconds,
      currentImageIndex,
      selectedImage,
      feedWidth,
    } = this.state;
    const {history} = this.props;
    const links = getHyperlinks(text);
    return !loadingPoster && !loading ? (
      <>
        <Loading
          size={100}
          color={'var(--white)'}
          loading={loading}
          right
          transparent={false}
        />
        <div className={styles.container}>
          <div className={styles.userActivityContainer}>
            <div
              onClick={() => {
                // when pasting a link
                if (history.action !== 'PUSH') history.replace('/feed');
                else history.goBack();
              }}
              className={styles.backIcon}>
              <ChevronBackIcon tintColor={'var(--magenta)'} />
            </div>
            <div
              className={styles.userImageContainer}
              onClick={() => history.push(`/profile/${poster?.id}`)}>
              <img
                className={styles.profileImage}
                src={poster?.profile_photo_url || DefaultProfileImg}
                alt="User Profile Image"
              />
            </div>
            <p>
              <span
                className={`namesAndObjects ${styles.selectable}`}
                onClick={() => history.push(`/profile/${poster?.id}`)}>
                {`${poster?.first_name} ${poster?.last_name}`}&nbsp;
              </span>
              {eventID ? (
                <span
                  className={`namesAndObjects ${styles.selectable}`}
                  onClick={() => history.push(`/events/${eventID}`)}>
                  {`> ${eventName || ''}`}
                </span>
              ) : groupID ? (
                <span
                  className={`namesAndObjects ${styles.selectable}`}
                  onClick={() => history.push(`/groups/${groupID}`)}>
                  {`> ${groupName || ''}`}
                </span>
              ) : challengeName ? (
                <span
                  className={`namesAndObjects ${styles.selectable}`}
                  onClick={() => history.push(`/challenges/${challengeID}`)}>
                  {`> ${challengeName || ''}`}
                </span>
              ) : (
                eventName
              )}
              &nbsp; • {howLongAgo(time)}
              {edited && <p className={'edited'}> Edited</p>}
            </p>
            <div className={styles.reportDeleteContainer}>
              {this.state.isPinned && (
                <div className={styles.pinnedContainer}>
                  <h4 className={styles.pinnedPostText}>PINNED POST</h4>
                  <PinnedPostIcon width="20px" height="20px" />
                </div>
              )}
              <ReportAndDeleteOverlay
                canDelete={this.canDelete()}
                deletePost={this.deletePost}
                blockPost={this.blockPost}
                streamID={streamID}
                posterID={actorID}
                type="post"
                text={text}
                tagged={tagged}
                image={media_url}
                images={post_images}
                eventStatusPost={eventID === true}
                eventID={eventID || null}
                groupID={groupID || null}
                mergeNewPost={this.initializeScreen}
                challengeID={this.state.challengeID}
                activity_sub_type={this.state?.event?.category}
                event_record_type={this.state.eventRecordType}
                group_record_type={this.state.groupRecordType}
                feed_origin_name={this.state.feedOrigin?.name}
                feed_origin_type={this.state.feedOrigin?.type}
                isAdmin={this.state.isAdmin}
                isPinned={this.state.isPinned}
                handlePinPost={() => this.setState({isPinned: true})}
                handleUnpinPost={() => this.setState({isPinned: false})}
                handleRemoveContent={() => this.props.history.goBack()}
                handleRemoveUser={() => null}
                graphData={this.state.graphData}
              />
            </div>
          </div>
          <div ref={this.feedContainerRef} className={styles.userPostContainer}>
            {workoutEventName ? (
              <ShareChallengeBox
                eventName={workoutEventName}
                chapterName={chapterName}
                eventStartTime={eventStartTime}
                miles={miles}
                steps={steps}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            ) : null}
            <FormattedPostText
              text={text}
              tagged={tagged}
              linkableUsers={false}
              history={history}
              clickable={true}
              links={links}
            />
            {links?.length > 0 && this.state.graphData?.url ? (
              <SitePreviewCard
                key={links[links?.length - 1]}
                link={links[links?.length - 1]}
                graphData={this.state.graphData}
              />
            ) : null}
            {(media_url || post_images?.length === 1) && (
              <img
                className={styles.postImage}
                src={post_images?.length === 1 ? post_images[0] : media_url}
                alt="User Post Image"
                onClick={() => this.setState({isModalOpen: true})}
              />
            )}
            <Carousel 
              images={post_images} 
              setImageIndex={this.setImageIndex}
              currentImageIndex={currentImageIndex}
              multiPhotoClicked={this.multiPhotoClicked}
              feedWidth={feedWidth}
            />
            <div className={styles.reactionContainer}>
              <div
                className={styles.likeContainer}
                onClick={this.handleLikeClicked}>
                <LikeIcon
                  className={styles.likeIcon}
                  tintColor={liked ? 'var(--magenta)' : null}
                />
                {likeAmount > 0 && (
                  <p
                    className={`namesAndObjects`}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.props.history.push({
                        pathname: `/feed/${streamID}/like`,
                        state: {
                          feed: {
                            id: streamID,
                            user: this.state.poster,
                          },
                        },
                      });
                      let analyticsObj = this.baseAnalyticsObj();
                      analyticsObj.profile_id = `${this.state.poster?.id}`;
                      analyticsObj.action_category = 'post';
                      logViewLikeList(analyticsObj);
                    }}>
                    {likeAmount} {likeAmount > 1 ? 'Likes' : 'Like'}
                  </p>
                )}
              </div>
              {commentAmount !== undefined && (
                <p className={`namesAndObjects`}>{`${commentAmount} ${
                  commentAmount != 1 ? 'comments' : 'comment'
                }`}</p>
              )}
            </div>
          </div>

          {commentAmount > 0 && (
            <SeePreviousComments
              refreshing={refreshing}
              onClick={this.loadMoreReactions}
              allCommentsLoaded={allCommentsLoaded}
            />
          )}

          <div
            className={styles.commentsContainer}
            ref={(el) => (this.commentsRef = el)}>
            {comments.length > 0 &&
              comments.map((comm) => (
                <CommentView
                  key={comm.comment_id}
                  user={comm.user}
                  time={comm.time}
                  comment={comm}
                  content={comm.content}
                  tagged={comm.tagged}
                  postID={streamID}
                  commentID={comm.comment_id}
                  posterID={poster?.id}
                  eventID={eventID || null}
                  handleCommentDeletion={this.handleCommentDeletion}
                  poster={poster}
                  posterText={text}
                  posterTagged={tagged}
                  posterTime={time}
                  refreshReactions={this.refreshReactions}
                  edited={comm.edited}
                  ownReactions={comm.own_reactions}
                  reactionCounts={comm.reaction_counts}
                  offset={comm.offset}
                  group={this.state.group}
                  groupID={this.state.groupID}
                  groupRecordType={this.state.groupRecordType}
                  eventRecordType={this.state.eventRecordType}
                  activitySubType={this.state.activitySubType}
                  feed_origin_name={this.state.feedOrigin?.name}
                  feed_origin_type={this.state.feedOrigin?.type}
                  graphData={comm.open_graph}
                  postGraphData={this.state.graphData}
                />
              ))}
          </div>
        </div>
        {!userProfile.getUserProfile().anonymous_profile && (
          <CreateCommentBar
            poster={poster}
            streamID={streamID}
            verbEvent={title || eventName}
            time={howLongAgo(time)}
            text={text}
            tagged={tagged}
            history={history}
            postImage={media_url}
            postImages={post_images}
            refreshReactions={this.refreshReactions}
            workout={{
              eventName: workoutEventName,
              chapterName: chapterName,
              eventStartTime: eventStartTime,
              miles: miles,
              steps: steps,
              hours: hours,
              minutes: minutes,
              seconds: seconds,
            }}
            event={this.state.event}
            group={this.state.group}
            groupID={this.state.groupID}
            challenge={this.state.challenge}
            challengeID={this.state.challengeID}
            groupRecordType={this.state.groupRecordType}
            feed_origin_name={this.state.feedOrigin?.name}
            feed_origin_type={this.state.feedOrigin?.type}
            graphData={this.state.graphData}
          />
        )}
        {isModalOpen && (
          <PhotoView
            media_url={media_url}
            post_images={post_images}
            index={selectedImage}
            onModalClose={() => this.setState({isModalOpen: false})}
            liked={liked}
            likeAmount={likeAmount}
            onLikeClicked={this.handleLikeClicked}
            setSelectedImage={this.setSelectedImage}
          />
        )}
      </>
    ) : null;
  }
}

export default withRouter(PostView);
