import React, {Component} from 'react';
import styles from './CommentView.module.css';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import LikeIcon from '../svgs/LikeIcon';
import ReportAndDeleteOverlay from '../ReportAndDeleteOverlay';
import {howLongAgo, getHyperlinks} from '../../../../shared/utils/Helpers';
import {withRouter} from 'react-router-dom';
import FormattedPostText from './FormattedPostText';
import {userProfile} from '../../../../shared/models/UserProfile';
import {rwbApi} from '../../../../shared/apis/api';
import {
  EXECUTION_STATUS,
  logDeleteComment,
  logLikeComment,
  logViewLikeList,
  webSectionName,
} from '../../../../shared/models/Analytics';
import SitePreviewCard from '../cards/SitePreviewCard';

class CommentView extends Component {
  constructor(props) {
    super(props);
    const {content, tagged, edited} = this.props;
    this.state = {
      deleted: false,
      content,
      tagged,
      edited,
      liked: this.props.ownReactions,
      likeCount: this.props.reactionCounts || 0,
      likeInProgress: false,
    };
  }

  // only the creator of a post can delete
  canDelete = () => {
    const commenterID = this.props.user.id.toString();
    const currentUserID = userProfile.getUserProfile().id.toString();
    return commenterID === currentUserID;
  };

  baseAnalyticsObj = () => {
    const {
      eventID,
      event,
      group,
      groupRecordType,
      groupID,
      challengeID,
      challenge,
      feed_origin_name,
      feed_origin_type,
      event_record_type,
      activity_sub_type,
      postID,
      commentID,
    } = this.props;
    let analyticsObj = {};
    if (event?.id) {
      analyticsObj.activity_sub_type = event.category;
      analyticsObj.event_record_type = event.is_virtual ? 'virtual' : 'event';
      analyticsObj.event_id = `${event.id}`;
    } else {
      if (eventID) {
        analyticsObj.event_id = `${eventID}`;
      }
      if (event_record_type) {
        analyticsObj.event_record_type = event_record_type;
      }
      if (activity_sub_type) {
        analyticsObj.activity_sub_type = activity_sub_type;
      }
    }
    if (group?.id) {
      analyticsObj.group_id = `${group.id}`;
      analyticsObj.group_record_type = group.type;
    } else {
      if (groupID) {
        analyticsObj.group_id = `${groupID}`;
      }
      if (groupRecordType) {
        analyticsObj.group_record_type = groupRecordType;
      }
    }
    if (challengeID) {
      analyticsObj.challenge_id = `${challengeID}`;
    } else if (challenge?.id) {
      analyticsObj.challenge_id = `${challenge.id}`;
    }
    if (feed_origin_name) {
      analyticsObj.feed_origin_name = feed_origin_name;
    }
    if (feed_origin_type) {
      analyticsObj.feed_origin_type = feed_origin_type;
    }
    if (postID) {
      analyticsObj.object_post = postID;
    }
    if (commentID) {
      analyticsObj.object_comment = commentID;
    }
    analyticsObj.section_name = webSectionName();
    return analyticsObj;
  };

  deleteComment = () => {
    if (
      window.confirm(
        'Delete Comment: Are you sure you want to delete your comment?',
      )
    ) {
      let analyticsObj = this.baseAnalyticsObj();
      analyticsObj.object_post = this.props.commentID;
      const reactionKind = JSON.stringify({kind: 'comment'});
      rwbApi
        .deleteReaction(
          this.props.posterID,
          this.props.postID,
          reactionKind,
          this.props.commentID,
        )
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
          this.props.handleCommentDeletion();
          this.setState({deleted: true});
        })
        .catch(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
        })
        .finally(() => {
          logDeleteComment(analyticsObj);
        });
    }
  };

  updateComment = (data) => {
    this.setState({
      content: data.content,
      tagged: data.tagged,
      edited: true,
    });
  };

  toggleLike = () => {
    if (this.state.likeInProgress) return;
    const currentUserID = userProfile.getUserProfile().id.toString();
    const likePayload = {
      kind: 'like',
      comment_id: this.props.commentID,
    };
    let analyticsObj = this.baseAnalyticsObj();
    if (this.props.offset) likePayload.offset = this.props.offset;
    if (this.state.liked) {
      this.setState({
        liked: false,
        likeCount: this.state.likeCount - 1,
        likeInProgress: true,
      });
      rwbApi
        .deleteReaction(
          currentUserID,
          this.props.postID,
          JSON.stringify(likePayload),
        )
        .catch(() => {
          alert('Error unliking the comment');
          this.setState({liked: true, likeCount: this.state.likeCount + 1});
        })
        .finally(() => {
          this.setState({likeInProgress: false});
        });
    } else {
      this.setState({
        liked: true,
        likeCount: this.state.likeCount + 1,
        likeInProgress: true,
      });
      rwbApi
        .postReaction(
          currentUserID,
          this.props.postID,
          JSON.stringify(likePayload),
        )
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
        })
        .catch(() => {
          alert('Error liking the comment');
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          this.setState({liked: false, likeCount: this.state.likeCount - 1});
        })
        .finally(() => {
          logLikeComment(analyticsObj);
          this.setState({likeInProgress: false});
        });
    }
  };

  render() {
    const {history, user, time} = this.props;
    const {content, tagged, edited} = this.state;
    const links = getHyperlinks(content);
    return !this.state.deleted ? (
      <div className={styles.commentContainer}>
        <div className={styles.userCommentContainer}>
          <div
            className={styles.userImageContainer}
            onClick={() => history.push(`/profile/${user.id}`)}>
            <img
              className={styles.profileImage}
              src={user.profile_photo_url || DefaultProfileImg}
              alt="User Profile Image"
            />
          </div>
          <p>
            <span
              className={`namesAndObjects ${styles.name}`}
              onClick={() => history.push(`/profile/${user.id}`)}>
              {`${user.first_name} ${user.last_name}`}&nbsp;
            </span>
            • {howLongAgo(time) || ''}
            {edited && <p className={'edited'}> Edited</p>}
          </p>
          <div className={styles.reportDeleteContainer}>
            <ReportAndDeleteOverlay
              updateComment={this.updateComment}
              canDelete={this.canDelete()}
              deletePost={this.deleteComment}
              streamID={this.props.postID}
              posterID={this.props.posterID}
              commentID={this.props.commentID}
              text={content}
              type="comment"
              eventStatusPost={false}
              tagged={tagged}
              mergeNewPost={() => null}
              poster={this.props.poster}
              time={howLongAgo(this.props.time)}
              posterText={this.props.posterText}
              posterTagged={this.props.posterTagged}
              posterTime={this.props.posterTime}
              refreshReactions={this.props.refreshReactions}
              eventID={this.props?.event?.id}
              groupID={this.props?.group?.id || this.props?.groupID}
              challengeId={
                this.props?.challenge?.id ||
                this.props?.challengeID ||
                this.props?.challengeId
              }
              activity_sub_type={
                this.props?.event?.category || this.props.activitySubType
              }
              event_record_type={
                this.props.eventRecordType ||
                this.props?.event?.is_virtual !== null
                  ? this.props?.event?.is_virtual
                    ? 'virtual'
                    : 'event'
                  : null
              }
              group_record_type={
                this.props.groupRecordType ||
                this.props.group_record_type ||
                this.props.group?.type
              }
              feed_origin_name={this.props.feed_origin_name}
              feed_origin_type={this.props.feed_origin_type}
              graphData={this.props.graphData}
              postGraphData={this.props.postGraphData}
            />
          </div>
        </div>
        <div className={styles.commentText}>
          <FormattedPostText
            text={content}
            tagged={tagged}
            linkableUsers={false}
            history={history}
            clickable={true}
            links={links}
          />
          {links.length && this.props.graphData?.url ? (
            <SitePreviewCard
              key={links[links.length - 1]}
              link={links[links.length - 1]}
              graphData={this.props.graphData}
            />
          ) : null}
          <div className={styles.likeContainer} onClick={this.handleLikePost}>
            <div
              className={styles.likeIconWrapper}
              onClick={() => this.toggleLike()}>
              <LikeIcon
                className={styles.likeIcon}
                tintColor={this.state.liked ? 'var(--magenta)' : null}
              />
            </div>
            {this.state.likeCount && this.state.likeCount > 0 ? (
              <p
                className={`namesAndObjects`}
                onClick={() => {
                  this.props.history.push({
                    pathname: `/feed/${this.props.postID}/comment/${this.props.commentID}/like`,
                    state: {feed: this.props.comment},
                  });
                  let analyticsObj = this.baseAnalyticsObj();
                  analyticsObj.action_category = 'comment';
                  // user is the commenter, poster is who created the post
                  analyticsObj.profile_id = `${this.props.user.id}`;
                  logViewLikeList(analyticsObj);
                }}>
                {this.state.likeCount}{' '}
                {this.state.likeCount > 1 ? 'Likes' : 'Like'}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withRouter(CommentView);
