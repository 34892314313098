import { rwbApi } from "../apis/api";
import { EXECUTION_STATUS, logDeletePost } from "../models/Analytics";

export const deletePost = (streamID, eventID, challengeID, groupID, analyticsObj) => {
    analyticsObj.click_text = 'delete post';
    if (eventID) {
        return rwbApi
            .deleteEventPost(eventID, streamID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                console.warn(err);
            })
            .finally(() => {
                logDeletePost(analyticsObj);
            });
    }
    else if (challengeID) {
        return rwbApi
            .deleteChallengePost(challengeID, streamID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                console.warn(err);
            })
            .finally(() => {
                logDeletePost(analyticsObj);
            });
    } else if (groupID) {
        return rwbApi
            .deleteGroupPost(groupID, streamID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                console.warn(err);
            })
            .finally(() => {
                logDeletePost(analyticsObj);
            });
    }
    else {
        return rwbApi
            .deleteUserPost(streamID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                console.warn(err);
            })
            .finally(() => {
                logDeletePost(analyticsObj);
            });
    }

};

// TODO: implement. This may be reversed as the aggregated feed card has some functionality that normal feed card may not
// if used, posts show up with images, posts can be liked, and comments can be made
export const deaggregateData = (data) => {
    if (!data.activities[0]) return data;
    return data.activities[0];
    
}

export const hasMediaChanged = (prevMedia, newMedia) => {
    if (prevMedia?.length !== newMedia?.length) {
        return true;
    }

    return prevMedia?.some((mediaItem, index) => 
        mediaItem !== newMedia[index]);
  }
