import React, {Component} from 'react';
import styles from './FeedListItem.module.css';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import LikeIcon from '../svgs/LikeIcon';
import EnhancedCard from './EnhancedCard';
import {howLongAgo, getHyperlinks, filterPostMedia} from '../../../../shared/utils/Helpers';
import {rwbApi} from '../../../../shared/apis/api';
import {Link, withRouter} from 'react-router-dom';
import FormattedPostText from './FormattedPostText';
import {
  EXECUTION_STATUS,
  logLikePost,
  logAccessMemberProfile,
  logAccessPost,
  logViewComments,
  webSectionName,
  extractFeedOriginInfo,
  logViewLikeList,
  logAccessPostPhoto,
} from '../../../../shared/models/Analytics';
import {userProfile} from '../../../../shared/models/UserProfile';
import ReportAndDeleteOverlay from '../ReportAndDeleteOverlay';
import {
  POST_BLOCK_ERROR,
  POST_DELETE_ERROR,
  STREAM_ERRORS,
} from '../../../../shared/constants/ErrorMessages';
import ListItemTitle from './ListItemTitle';
import PhotoView from './PhotoView';
import AdminIcon from '../svgs/AdminIcon';
import ShareChallengeBox from './ShareChallengeBox';
import {
  POST_BLOCK_WARNING,
  POST_DELETE_WARNING,
} from '../../../../shared/constants/OtherMessages';
import {deletePost} from '../../../../shared/utils/StreamHelpers';
import PinnedPostIcon from '../svgs/PinnedPostIcon';
import SitePreviewCard from '../cards/SitePreviewCard';
import Carousel from './Carousel';

class FeedListItem extends Component {
  constructor(props) {
    super(props);
    const {data, origin} = props;
    this.feedContainerRef = React.createRef();
    this.postType = this.props.data?.foreign_id?.split(':')[0];
    this.eventID = this.props.eventID || this.props.data.event?.id;
    this.streamID = this.props.data.id;
    this.postImages = filterPostMedia(this.props.data?.media, 'image');
    this.origin = (data.origin && data.origin.split(':')[0]) || origin || null;
    this.feedOrigin = extractFeedOriginInfo(
      this.props.data.foreign_id,
      this.props.data.object,
    );
    this.state = {
      loaded: false,
      liked:
        this.props.data.own_reactions && this.props.data.own_reactions.like, // if own_reactions.like, that means the post has been liked
      likeAmount:
        (this.props.data.reaction_counts &&
          this.props.data.reaction_counts.like) ||
        0,
      deleted: false, // used to hide card after deleted
      commentAmount: this.props.data.reaction_counts?.comment || 0,
      isModalOpen: false,
      currentImageIndex: 0,
      selectedImage: 0,
      feedWidth: 0,
      isPinned: this.props?.data?.isPinned || this.props?.data?.is_pinned
    };
  }
  //navigating is used to differentiate between displaying on the card, and the value sent to the navigated page
  displayTitle = (navigating) => {
    // will need to add a check for event name after enrichment
    const {type, history} = this.props;
    const {verb, event, group, challenge} = this.props.data;
    return ListItemTitle(
      history,
      navigating,
      type,
      verb,
      event,
      group,
      challenge,
    );
  };

  updateLikeStatus = (action) => {
    if (action === 'like')
      this.setState({liked: true, likeAmount: this.state.likeAmount + 1});
    else if (action === 'unlike')
      this.setState({liked: false, likeAmount: this.state.likeAmount - 1});
  };

  handleLikePost = () => {
    const reactionKind = JSON.stringify({kind: 'like'});
    // posts on events are 'event_post's, but do not have an ID. Do not treat it like an event post
    if (
      this.postType === 'event_post' &&
      this.eventID &&
      this.origin !== 'user'
    ) {
      this.eventPostLike(reactionKind);
    }
    // other postTypes might be "attend",
    else this.userPostLike(reactionKind);
  };

  onImgLoad = ({target: img}) => {
    if (img.offsetWidth / img.offsetHeight < 0.75) {
      this.setState({tallImg: true});
    }
  };

  eventPostLike = (reactionKind) => {
    let analyticsObj = this.baseAnalyticsObj();
    if (!this.state.liked) {
      this.updateLikeStatus('like');
      rwbApi
        .postEventReaction(this.eventID, this.streamID, reactionKind)
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
        })
        .catch((err) => {
          if (err === STREAM_ERRORS.ALREADY_REACTED) {
            alert("You've already liked this post. Please refresh your feed.");
            analyticsObj.execution_status = EXECUTION_STATUS.success;
          } else {
            alert('Error liking post. Please try again later.');
            analyticsObj.execution_status = EXECUTION_STATUS.failure;
          }
          this.updateLikeStatus('unlike');
        })
        .finally(() => {
          logLikePost(analyticsObj);
        });
    } else {
      this.updateLikeStatus('unlike');
      rwbApi
        .deleteEventReaction(this.eventID, this.streamID, reactionKind)
        .then(() => {})
        .catch((err) => {
          if (err === STREAM_ERRORS.REACTION_NOT_FOUND)
            alert(
              "You've already unliked this post or it was deleted. Please refresh your feed.",
            );
          else alert('Error unliking post. Please try again later.');
          this.updateLikeStatus('like');
        });
    }
  };

  // for timeline/feed posts
  userPostLike = (reactionKind) => {
    const creatorID = this.props.data.user.id;
    let analyticsObj = this.baseAnalyticsObj();
    if (!this.state.liked) {
      this.updateLikeStatus('like');
      rwbApi
        .postReaction(creatorID, this.streamID, reactionKind)
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
        })
        .catch((err) => {
          if (err === STREAM_ERRORS.ALREADY_REACTED) {
            alert("You've already liked this post. Please refresh your feed.");
            analyticsObj.execution_status = EXECUTION_STATUS.success;
          } else {
            alert('Error liking post. Please try again later.');
            analyticsObj.execution_status = EXECUTION_STATUS.failure;
          }
          this.updateLikeStatus('unlike');
        })
        .finally(() => {
          logLikePost(analyticsObj);
        });
    } else {
      this.updateLikeStatus('unlike');
      rwbApi
        .deleteReaction(creatorID, this.streamID, reactionKind)
        .then(() => {})
        .catch((err) => {
          if (err === STREAM_ERRORS.REACTION_NOT_FOUND)
            alert(
              "You've already unliked this post or it was deleted. Please refresh your feed.",
            );
          else alert('Error unliking post. Please try again later.');
          this.updateLikeStatus('like');
        });
    }
  };

  canDelete = () => {
    const postCreator = this.props.data.actor.split(':')[1]; //ID of post creator
    const currentUserID = userProfile.getUserProfile().id.toString();
    return postCreator === currentUserID && this.props.type !== 'event';
  };

  baseAnalyticsObj = () => {
    let analyticsObj = {
      object_post: this.props.data.id,
      feed_origin_name: this.feedOrigin.name,
      feed_origin_type: this.feedOrigin.type,
      section_name: webSectionName(),
    };
    if (this.props?.data?.group) {
      analyticsObj.group_id = `${this.props.data.group.id}`;
      analyticsObj.group_record_type = this.props.data?.group?.type;
    } else if (this.props?.group && this.props?.group?.id != 0) {
      analyticsObj.group_id = `${this.props.group.id}`;
      // analyticsObj.group_record_type = this.props.group?.type; // waiting on backend
    }
    if (this.props?.groupRecordType)
      analyticsObj.group_record_type = this.props?.groupRecordType;
    else if (this.props?.group_record_type)
      analyticsObj.group_record_type = this.props.group_record_type;
    if (this.props?.data?.challenge)
      analyticsObj.challenge_id = `${this.props.data.challenge.id}`;
    else if (this.props.challenge_id && this.props.challenge_id !== 0)
      analyticsObj.challenge_id = `${this.props.challenge_id}`;
    if (this.props?.data?.event) {
      analyticsObj.event_id = `${this.props.data.event.id}`;
      analyticsObj.activity_sub_type = this.props.data.event.category;
      analyticsObj.event_record_type = this.props.data.event.is_virtual
        ? 'virtual'
        : 'event';
    } else if (this.props?.event) {
      analyticsObj.event_id = `${this.props.event.id}`;
      analyticsObj.activity_sub_type = this.props.event.activity_sub_type;
      analyticsObj.event_record_type = this.props.event.event_record_type;
    }

    return analyticsObj;
  };

  deletePost = () => {
    if (window.confirm(`Delete Post: ${POST_DELETE_WARNING}`)) {
      let analyticsObj = this.baseAnalyticsObj();
      analyticsObj.click_text = 'delete post';
      this.setState({deleted: true});
      const data = this.props.data;
      const streamID = data.id;
      const eventID = data?.event_id;
      const challengeID = data?.challenge?.id || data?.challenge_id;
      const groupID = data?.group?.id || data?.group_id;
      deletePost(streamID, eventID, challengeID, groupID, analyticsObj).catch(
        () => {
          this.setState({deleted: false});
        },
      );
    }
  };

  blockPost = () => {
    if (window.confirm(`Block Post: ${POST_BLOCK_WARNING}`)) {
      this.setState({deleted: true});
      rwbApi
        .blockPost(this.props.data.id)
        .then(() => {})
        .catch(() => {
          alert(POST_BLOCK_ERROR);
          this.setState({deleted: false});
        });
    }
  };

  determinePostRoute = () => {
    const {group_id, event_id, challenge_id, id, user} = this.props.data;
    const {origin} = this.props;
    if (group_id && origin !== 'user') {
      return `/groups/${group_id}/feed/${id}/user/${user.id}`;
    } else if (event_id && origin !== 'user') {
      return `/events/${event_id}/feed/${id}/user/${user.id}`;
    } else if (challenge_id && origin !== 'user') {
      return `/challenges/${challenge_id}/feed/${id}/user/${user.id}`;
    } else if (webSectionName().toLowerCase() === 'profile') {
      return `/profile/feed/${id}/user/${user.id}`;
    } else {
      return `/feed/${id}`;
    }
  };

  setImageIndex = (index) => {
    this.setState({ currentImageIndex: index });
  };

  setSelectedImage = (newIndex) => {
    this.setState({ selectedImage: newIndex });
  };

  multiPhotoClicked = (index) => {
    logAccessPostPhoto(this.baseAnalyticsObj());
    this.setState({ isModalOpen: true }); 
    this.setState({selectedImage: index})
  }

  componentDidMount() {
    this.updateFeedWidth(); 
    window.addEventListener('resize', this.updateFeedWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateFeedWidth);
  }

  updateFeedWidth = () => {
    if (this.feedContainerRef.current) {
      this.setState({
        feedWidth: this.feedContainerRef.current.offsetWidth
      });
    }
  };

  navigatePostState = () => {
    const {liked, likeAmount, commentAmount} = this.state;
    const {
      id,
      user,
      time,
      text,
      event,
      media_url,
      tagged,
      actor,
      group_id,
      is_pinned,
    } = this.props.data;
    return {
      post_image: media_url,
      text,
      poster: user,
      time,
      tagged,
      eventID: this.eventID || null,
      streamID: id,
      liked,
      likeAmount,
      title: this.displayTitle(true),
      eventName: event ? event.name : null,
      actorID: actor.split(':')[1],
      activityID: id,
      commentAmount,
      groupID: this.props.data.group_id || this.props?.group?.id,
      challengeID: this.props.challenge_id || this.props?.data?.challenge?.id,
      groupRecordType:
        this.props?.data?.group?.type ||
        this.props?.group?.type ||
        this.props?.groupRecordType ||
        this.props.group_record_type,
      eventRecordType:
        this.props?.event?.event_record_type ||
        this.props?.data?.event?.is_virtual != null
          ? this.props?.data?.event?.is_virtual
            ? 'virtual'
            : 'event'
          : null,
      activitySubType:
        this.props?.data?.event?.category ||
        this.props?.event?.activity_sub_type,
      isPinned: this.state.isPinned,
      isAdmin: this.props.isAdmin,
    };
  };

  render() {
    const {
      id,
      user,
      time,
      text,
      event,
      media_url,
      media,
      tagged,
      actor,
      group_id,
      open_graph,
    } = this.props.data;
    const {liked, likeAmount, deleted, commentAmount, isModalOpen, currentImageIndex, selectedImage, feedWidth} = this.state;
    const {type, history} = this.props;
    const links = getHyperlinks(text);
    return !deleted && user?.id && event?.id !== null ? (
      <div className={styles.container} ref={this.feedContainerRef}>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
          {this.state.isPinned ? (
            <div className={styles.pinnedContainer}>
              <h4 className={styles.pinnedPostText}>PINNED POST</h4>
              <PinnedPostIcon width="20px" height="20px" />
            </div>
          ) : null}
          <ReportAndDeleteOverlay
            canDelete={this.canDelete()}
            deletePost={this.deletePost}
            blockPost={this.blockPost}
            streamID={this.streamID}
            groupID={this.props.data.group_id}
            posterID={this.props.data.actor.split(':')[1]}
            workout={
              this.props.data.workout?.event_id ? this.props.data.workout : null
            }
            feedID={this.props.data.id}
            text={text}
            image={media_url}
            images={this?.postImages}
            type="post"
            eventStatusPost={type === 'event'}
            eventID={this.eventID || null}
            tagged={tagged}
            mergeNewPost={this.props.mergeNewPost}
            isAdmin={this.props.isAdmin}
            isPinned={this.state.isPinned}
            challengeID={
              this.props.challenge_id || this.props?.data?.challenge?.id
            }
            group_record_type={
              this.props?.data?.group?.type ||
              this.props?.group?.type ||
              this.props?.groupRecordType ||
              this.props.group_record_type
            }
            feed_origin_name={this.feedOrigin.name}
            feed_origin_type={this.feedOrigin.type}
            activity_sub_type={
              this.props?.data?.event?.category ||
              this.props?.event?.activity_sub_type
            }
            event_record_type={
              this.props?.event?.event_record_type ||
              this.props?.data?.event?.is_virtual != null
                ? this.props?.data?.event?.is_virtual
                  ? 'virtual'
                  : 'event'
                : null
            }
            handlePinPost={this.props.mergeNewPost}
            handleUnpinPost={this.props.mergeNewPost}
            handleRemoveContent={this.props.mergeNewPost}
            handleRemoveUser={() => null}
            graphData={open_graph}
          />
        </div>
        <div className={styles.userActivityContainer}>
          <div
            className={styles.userImageContainer}
            onClick={() => {
              history.push(`/profile/${user.id}`);
              logAccessMemberProfile({
                ...this.baseAnalyticsObj(),
                section_name: webSectionName(),
              });
            }}>
            <img
              className={styles.profileImage}
              src={user?.profile_photo_url || DefaultProfileImg}
              alt="User Profile Image"
            />
            {/* only show on a sponsor group the poster is an admin of */}
            {this.props.isSponsor && user?.is_sponsor_admin && (
              <AdminIcon className={styles.sponsorAdminIcon} />
            )}
          </div>
          <p>
            <span
              className={`namesAndObjects ${styles.name}`}
              onClick={() => history.push(`/profile/${user.id}`)}>
              {`${user?.first_name} ${user?.last_name}`}&nbsp;
            </span>
            {this.displayTitle(false)}&nbsp;
            {/* <span className="namesAndObjects">Object&nbsp;</span> */}•{' '}
            {howLongAgo(time)}
            {this.props.data?.edited && <p className={'edited'}> Edited</p>}
          </p>
        </div>
        {type === 'event' && this.props.data?.event && (
          <EnhancedCard event={event} />
        )}
        <Link
          onClick={() => {
            let analyticsObj = this.baseAnalyticsObj();
            analyticsObj.has_image = media_url ? true : false;
            logAccessPost(analyticsObj);
          }}
          to={{
            pathname: this.determinePostRoute(),
            state: this.navigatePostState(),
          }}>
          {this.props.data.workout?.event_id ? (
            <ShareChallengeBox
              eventName={this.props.data.workout.event_name}
              chapterName={this.props.data.workout.chapter_name}
              eventStartTime={this.props.data.workout.event_start_time}
              miles={this.props.data.workout.miles}
              steps={this.props.data.workout.steps}
              hours={Math.floor(this.props.data.workout.minutes / 60)}
              minutes={Math.floor(this.props.data.workout.minutes % 60)}
              seconds={Math.round((this.props.data.workout.minutes % 1) * 60)}
            />
          ) : null}
          <FormattedPostText
            text={text}
            tagged={tagged || []}
            linkableUsers={false}
            history={history}
            clickable={false}
            links={links}
          />
        </Link>
        {links.length && open_graph?.url ? (
          <SitePreviewCard
            key={links[links.length - 1]}
            link={links[links.length - 1]}
            graphData={open_graph}
          />
        ) : null}
        {(media_url || this.postImages?.length === 1) && (
          <div
            className={
              this.state.tallImg
                ? `${styles.tallContainer} ${styles.postImageContainer}`
                : styles.postImageContainer
            }>
            <img
              className={[
                this.state.tallImg
                  ? `${styles.tallImage} ${styles.postImage}`
                  : styles.postImage,
              ]}
              src={this.postImages?.length === 1 ? this.postImages[0] : media_url}
              alt="User Post Image"
              onClick={() => this.setState({isModalOpen: true})}
              onLoad={this.onImgLoad}
            />
          </div>
        )}
        <Carousel
          images={this.postImages} 
          setImageIndex={this.setImageIndex}
          currentImageIndex={currentImageIndex}
          multiPhotoClicked={this.multiPhotoClicked}
          feedWidth={feedWidth}
        />
        <div className={styles.reactionContainer}>
          <div className={styles.likeContainer} onClick={this.handleLikePost}>
            <LikeIcon
              className={styles.likeIcon}
              tintColor={liked ? 'var(--magenta)' : null}
            />
            {likeAmount > 0 ? (
              <p
                className={`namesAndObjects`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.props.history.push({
                    pathname: `/feed/${this.props.data.id}/like`,
                    state: {
                      feed: this.props.data,
                    },
                  });
                  let analyticsObj = this.baseAnalyticsObj();
                  analyticsObj.action_category = 'post';
                  analyticsObj.profile_id = `${this.props.data?.user?.id}`;
                  logViewLikeList(analyticsObj);
                }}>
                {likeAmount} {likeAmount > 1 ? 'Likes' : 'Like'}
              </p>
            ) : null}
          </div>
          <div
            className={styles.commentsContainer}
            onClick={() => {
              let analyticsObj = this.baseAnalyticsObj();
              analyticsObj.click_text = `${commentAmount} ${
                commentAmount > 1 ? 'comments' : 'comment'
              }`;
              logViewComments(analyticsObj);
              this.props.history.push({
                pathname: this.determinePostRoute(),
                state: this.navigatePostState(),
              });
            }}>
            <p className={`namesAndObjects`}>{`${commentAmount} ${
              commentAmount > 1 ? 'comments' : 'comment'
            }`}</p>
          </div>
        </div>
        {isModalOpen && (
          <PhotoView
            media_url={media_url}
            post_images={this.postImages}
            index={selectedImage}
            onModalClose={() => this.setState({isModalOpen: false})}
            liked={liked}
            likeAmount={likeAmount}
            onLikeClicked={this.handleLikePost}
            setSelectedImage={this.setSelectedImage}
          />
        )}
      </div>
    ) : null;
  }
}

export default withRouter(FeedListItem);
